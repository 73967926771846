import { Box, Button, Card, Typography } from "@mui/material";
import axios from "configs/axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import ArgonButton from "components/ArgonButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const CkEditorComponent = () => {
  const actionDispatcher = useDispatch();
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState("");

  // ******************************Stored VAlue inside the state**********************************
  const handleEditorChange = (editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  // *********************************************************************************************

  const handleShowData = async (event) => {
    event.preventDefault();
    actionDispatcher(isLoading(true));
    // console.log("======>editor====>", editorData);
    try {
      const res = await axios.post(`admin/add/or/update/gurash/saving/data`, {
        data: editorData,
      });
      // console.log("SingleUsers Business===>", res?.data);
      setEditorData("");
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
      actionDispatcher(isLoading(false));
      navigate("/gurashSaving");
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error===>", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ paddingY: "1rem", paddingX: "1rem", overflowY: "auto" }}>
        <Box display={"flex"} justifyContent={"space-between"} my={2}>
          <Typography variant="h5">Gurash Saving</Typography>
          <ArgonButton
            variant="gradient"
            color="success"
            onClick={() => navigate("/gurashSavingDisplay")}
          >
            PreviousData
          </ArgonButton>
        </Box>
        <form onSubmit={handleShowData}>
          <CKEditor
            editor={Editor}
            // config={editorConfiguration}
            config={{
              ckfinder: {
                // Upload the images to the server using the CKFinder QuickUpload command
                // You have to change this address to your server that has the ckfinder php connector
                uploadUrl: "admin/save/gurash/saving/image",
              },
            }}
            data={editorData ? editorData : ""}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => handleEditorChange(editor)}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <ArgonButton variant="gradient" color="success" type={"submit"}>
              Submit
            </ArgonButton>
          </Box>
        </form>
      </Card>
    </DashboardLayout>
  );
};

export default CkEditorComponent;
