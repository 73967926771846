import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import ArgonInput from "components/ArgonInput";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { Delete } from "@mui/icons-material";

// Style of Modal section set in center
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 4,
  p: 2,
};
// ******************************

const RealState = ({ open, setOpen, category }) => {
  // console.log("realstate=====>", category);
  const actionDispatcher = useDispatch();
  const [fields, setFields] = useState([""]);
  const [subCategory, setSubCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const handleClose = () => {
    setOpen(!open);
  };

  //   // ************************** SHandle Submit Add category here  *******************************

  const handleFormSubmit = async (event) => {
    actionDispatcher(isLoading(true));
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("mainCategoryName", category);
      formData.append("categoryName", subCategory);
      formData.append("image", selectedFile);

      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.post("admin/add/categories", formData);
      actionDispatcher(isLoading(false));
      setSubCategory("");
      setSelectedFile(null);
      setPreviewURL(null);
      actionDispatcher(openSnackbar(response?.data?.message, "success"));
      handleClose();
      // console.log("Category updated successfully:", response.data);
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.error("Error uploading image:", error);
    }
  };
  //   // **************************************************************************************

  //   // ************************** Store selected dropdown value into a state  *******************************

  const handleChangeSub = async (event) => {
    setSubCategory(event.target.value);
  };

  //   // **************************************************************************************

  //   // ************************** Store selected Images into a state  *******************************

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewURL(URL.createObjectURL(file));
  };

  //   // **************************************************************************************

  //   // ***************** Add multiple list inside category***********************************
  const handleFieldChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };

  const addField = () => {
    setFields([...fields, ""]);
  };

  //   // *****************************************************************************************

  //   // ***************** Remove particular field inside list category***************************
  const removeField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  //   // *****************************************************************************************

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
            backgroundColor: "rgb(0 0 0 / 15%)",
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: "1.5rem" }}>
            <Typography variant="h5">{category.toUpperCase()}</Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
          <CardContent display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Category</Typography>
                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    value={category}
                    disable
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                  />
                </Grid>

                <Grid item container xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6"> Management Fee Category</Typography>
                  </Grid>
                  {fields.map((value, index) => (
                    <>
                      <Grid item xs={10} sm={10}>
                        <ArgonInput
                          key={index}
                          value={value}
                          onChange={(e) => handleFieldChange(index, e.target.value)}
                          label={`Field ${index + 1}`}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <IconButton onClick={() => removeField(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addField}
                      sx={{ color: "white !important" }}
                    >
                      Add Management
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6"> Separate Management Fee Category</Typography>
                  </Grid>
                  {fields.map((value, index) => (
                    <>
                      <Grid item xs={10} sm={10}>
                        <ArgonInput
                          key={index}
                          value={value}
                          onChange={(e) => handleFieldChange(index, e.target.value)}
                          label={`Field ${index + 1}`}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <IconButton onClick={() => removeField(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addField}
                      sx={{ color: "white !important" }}
                    >
                      Add Separate Management
                    </Button>
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6"> Facilities</Typography>
                  </Grid>
                  {fields.map((value, index) => (
                    <>
                      <Grid item xs={10} sm={10}>
                        <ArgonInput
                          key={index}
                          value={value}
                          onChange={(e) => handleFieldChange(index, e.target.value)}
                          label={`Field ${index + 1}`}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <IconButton onClick={() => removeField(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addField}
                      sx={{ color: "white !important" }}
                    >
                      Add Facilities
                    </Button>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} sm={12}>
                  <Typography variant="h6"> Banefit of House</Typography>

                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    // type="file"
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Grid> */}

                <Grid item container xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6"> Banefit of House</Typography>
                  </Grid>
                  {fields.map((value, index) => (
                    <>
                      <Grid item xs={10} sm={10}>
                        <ArgonInput
                          key={index}
                          value={value}
                          onChange={(e) => handleFieldChange(index, e.target.value)}
                          label={`Field ${index + 1}`}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <IconButton onClick={() => removeField(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addField}
                      sx={{ color: "white !important" }}
                    >
                      Add Banefit of House
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    bgcolor="#4bcfde"
                    sx={{ color: "white !important" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Box>
      </Modal>
    </>
  );
};
RealState.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  category: PropTypes.string,
};

export default RealState;
