import { Box } from "@mui/material";
import axios from "configs/axios";
import ArgonTypography from "components/ArgonTypography";
import CategoriesList from "examples/Lists/CategoriesList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function CategoryData(params) {
  const tempArray = params?.map((item, index) => {
    return {
      //   user: [item?.image?.imageUrl, `${item.userName}`],
      //   email: item.email,
      //   mobile: `(${item.countryCode}) ${item.contactNo}`,
      //   createdAt: dayjs(`${item.createdAt}`).format("DD/MM/YY h:mm A"),

      color: "dark",
      icon: (
        <i
          className={
            item?.category === "agricultural goods"
              ? "ni ni-atom"
              : `${
                  item?.category === "part time job"
                    ? "ni ni-mobile-button"
                    : `${
                        item?.category === "used vehicles"
                          ? "ni ni-bus-front-12"
                          : `${
                              item?.category === "extracurricular class"
                                ? "ni ni-briefcase-24"
                                : `${
                                    item?.category === "selling my item"
                                      ? "ni ni-bag-17"
                                      : "ni ni-building"
                                  }
                                `
                            }`
                      }
                    `
                }`
          }
          style={{ fontSize: "12px" }}
        />
      ),

      name: `${item?.category}`,
      description: (
        <>
          <ArgonTypography variant="caption" color="text" fontWeight="medium">
            No of Products {item?.noOfProducts}
          </ArgonTypography>
        </>
      ),
      route: "/",
    };
  });
  return tempArray;
}

const DashboardCategoryList = () => {
  const [data, setData] = useState([]);
  const search = useSelector((state) => state.search);
  const categoryDashList = [
    "part time job",
    "agricultural goods",
    "used vehicles",
    "extracurricular class",
    "real estate",
    "selling my item",
  ];
  // ************************** Get particular category data *******************************

  const getSelectCategory = async () => {
    try {
      const categoryData = categoryDashList?.map(async (name) => {
        const res = await axios.get(
          `admin/get/category/wise/all/products/${name}/?limitPerPage=2&pageNo=1&search=${search.query}`
        );
        // console.log("categoryData?", res?.data);
        return res?.data;
      });

      Promise.all(categoryData).then((categoryData) => {
        const validResults = categoryData.filter((categoryData) => categoryData !== null);
        setData(validResults);
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  // **************************************************************************************
  useEffect(() => {
    getSelectCategory();
  }, [search.query]);

  return (
    <Box sx={{ height: "100%" }}>
      <CategoriesList title="categories" categories={CategoryData(data)} />
    </Box>
  );
};

export default DashboardCategoryList;
