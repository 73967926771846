// @mui material components
import Card from "@mui/material/Card";

// Gurash Dahboard MUI components
import ArgonBox from "components/ArgonBox";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

// Gurash Dahboard MUI

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import imagesNotFound from "../../../assets/images/productNotFound.jpg";

// Gurash Image Import
import { useEffect, useState } from "react";
import axios from "configs/axios";
import dayjs from "dayjs";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import SellingDeleteModal from "./Single DataDetails/SellingDeleteModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

function HomeProductSection() {
  const navigate = useNavigate();
  const actionDispatcher = useDispatch();
  const search = useSelector((state) => state.search);
  const [category, setCategory] = useState("all");
  const [optionData, setOptionData] = useState([]);
  const [data, setData] = useState([]);
  const [pageChange, setPageChange] = useState(1);
  const [totalPage, setTotalPage] = useState(10);

  // **************************** navigate Single  Prodcuct Details *********************************

  const handleSingleProduct = (id) => {
    navigate(`/product/singleProduct/${id}`);
  };
  // **************************************************************************************

  // **************************** navigate Vefication Table SECTION Details ********************************
  const handleNavigate = () => {
    // Navigate to a different route when called
    navigate(`/product/verification`);
  };
  // **************************************************************************************

  // ************************** Get main category data of Dropdown Section *******************************

  const getMainCategory = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.get(`admin/get/main/categories`);
      // console.log("resssmaincategory=====>", res?.data?.allMainCategories);
      actionDispatcher(isLoading(false));
      setOptionData(res?.data?.allMainCategories);
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // **************************************************************************************

  // ************************** Get particular category data *******************************

  const handleCategory = async (category, page) => {
    actionDispatcher(isLoading(true));

    try {
      const res = await axios.get(
        `admin/get/category/wise/all/products/${category}/?limitPerPage=50&pageNo=${
          page ? page : 1
        }&search=${search.query}`
      );
      actionDispatcher(isLoading(false));

      setData(res?.data);
      setTotalPage(res.data.noOfPages);
      // getLocation();
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };

  // **************************************************************************************
  const handlePageChange = (page) => {
    handleCategory(category, page);
    setPageChange(page);
  };
  // ===============================================================================================

  // ************************** Store selected dropdown value into a state  *******************************

  const handleChange = async (event) => {
    setCategory(event.target.value);
    handleCategory(event.target.value);
  };

  // **************************************************************************************

  useEffect(() => {
    getMainCategory();
    handleCategory("all");

    // {
    //   sell ? handleCategory("selling my item") : handleCategory("all");
    // }
  }, [search.query]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Outlet />
      <ArgonBox py={3}>
        <Box sx={{ minWidth: "200px", maxWidth: "200px", marginY: "2rem", marginX: "1.5rem" }}>
          <TextField
            sx={{ m: 1, minWidth: 80, width: "100%", textTransform: "capitalize" }}
            onChange={handleChange}
            select
            value={category ? category : "all"}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {optionData?.map((option) => {
              return (
                <MenuItem
                  key={option._id}
                  sx={{ textTransform: "capitalize" }}
                  value={option?.category}
                >
                  {option?.category}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>

        <Box>
          {data?.category === "all" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 5,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {data?.allProducts?.map((data, index) => {
                  return (
                    <Box
                      key={index}
                      xs={12}
                      sm={5.75}
                      md={3.75}
                      lg={2.75}
                      flexDirection={"row"}
                      sx={{
                        position: "relative",
                        "&:hover .MuiBox-root": {
                          transition: "all 300ms ease",
                          opacity: "1",
                          height: "12.5rem",
                        },
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: 200,
                          maxWidth: 200,
                          minHeight: 200,
                          maxHeight: 200,
                          padding: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {(data?.images && data?.images?.length > 0) ||
                        (data?.imagesForSale && data?.imagesForSale?.length > 0) ? (
                          <img
                            src={
                              data?.imagesForSale
                                ? data?.imagesForSale[0]?.imageUrl
                                : data?.images[0]?.imageUrl
                            }
                            alt="Image"
                            loading="lazy"
                            style={{
                              minWidth: "11rem",
                              maxWidth: "11rem",
                              minHeight: "5rem",
                              maxHeight: "5rem",
                              borderRadius: "4px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img
                            src={imagesNotFound}
                            alt="Image"
                            loading="lazy"
                            style={{
                              minWidth: "11rem",
                              maxWidth: "11rem",
                              minHeight: "5rem",
                              maxHeight: "5rem",
                              borderRadius: "4px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        <Box>
                          <Typography variant="h6" color="text.secondary" gutterBottom>
                            {data?.charges ? <>रू {data?.charges}</> : <>रू {data?.price}</>}
                          </Typography>

                          <Typography variant="h6">
                            {data?.type ? <>{data?.type}</> : <>{data?.title}</>}
                          </Typography>

                          <Grid container>
                            <Grid item sm={8} md={8} lg={8}>
                              <Typography sx={{ fontSize: "12px" }} color="text.secondary">
                                {data?.address?.wardName} &nbsp; {data?.address?.wardNumber}
                              </Typography>
                            </Grid>
                            <Grid item sm={4} md={4} lg={4}>
                              <Typography
                                sx={{ fontSize: "12px", textAlign: "end" }}
                                color="text.secondary"
                              >
                                {dayjs(data?.updatedAt).format("DD/MM/YY")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                      <Box
                        sx={{
                          top: 0,
                          opacity: "0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          width: "100%",
                          height: "87%",
                          borderRadius: "6px",
                          bgcolor: "rgba(0, 0, 0, 0.5)",
                          zIndex: "1000",
                        }}
                      >
                        <Stack direction={"row"}>
                          <IconButton
                            sx={{
                              color: "white",
                            }}
                            onClick={() => {
                              handleSingleProduct(data?._id);
                            }}
                          >
                            <VisibilityIcon color="white" />
                          </IconButton>
                          <SellingDeleteModal id={data?._id} handleCategory={handleCategory} />
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : (
            <>
              <Box>
                {data?.category === "part time job" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 5,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {data?.allProducts?.map((data, index) => {
                      return (
                        <Box
                          key={index}
                          xs={12}
                          sm={5.75}
                          md={3.75}
                          lg={2.75}
                          flexDirection={"row"}
                          sx={{
                            position: "relative",
                            "&:hover .MuiBox-root": {
                              transition: "all 300ms ease",
                              opacity: "1",
                              height: "12.5rem",
                            },
                          }}
                        >
                          <Card
                            sx={{
                              minWidth: 200,
                              maxWidth: 200,
                              minHeight: 200,
                              maxHeight: 200,

                              padding: "1rem",
                              cursor: "pointer",
                            }}
                          >
                            {data?.images && data?.images.length > 0 ? (
                              <img
                                src={data?.images[0]?.imageUrl}
                                alt="Image"
                                loading="lazy"
                                style={{
                                  minWidth: "11rem",
                                  maxWidth: "11rem",
                                  minHeight: "5rem",
                                  maxHeight: "5rem",
                                  borderRadius: "4px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={imagesNotFound}
                                alt="Image"
                                loading="lazy"
                                style={{
                                  minWidth: "11rem",
                                  maxWidth: "11rem",
                                  minHeight: "5rem",
                                  maxHeight: "5rem",
                                  borderRadius: "4px",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                            <Box>
                              {data?.category === "part time job" ? (
                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                  रू {data?.salary}
                                </Typography>
                              ) : (
                                <Typography variant="h5" color="text.secondary" gutterBottom>
                                  ₹ {data?.Price}
                                </Typography>
                              )}

                              <Typography variant="h6" overflow={"auto"}>
                                {data?.title}
                              </Typography>

                              <Grid container>
                                <Grid item sm={8} md={8} lg={8}>
                                  <Typography sx={{ fontSize: "12px" }} color="text.secondary">
                                    {data?.address?.wardName} &nbsp; {data?.address?.wardNumber}
                                  </Typography>
                                </Grid>
                                <Grid item sm={4} md={4} lg={4}>
                                  <Typography
                                    sx={{ fontSize: "12px", textAlign: "end" }}
                                    color="text.secondary"
                                  >
                                    {dayjs(data?.updatedAt).format("DD/MM/YY")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Card>
                          <Box
                            sx={{
                              top: 0,
                              opacity: "0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              width: "100%",
                              height: "87%",
                              borderRadius: "6px",
                              bgcolor: "rgba(0, 0, 0, 0.5)",
                              zIndex: "1000",
                            }}
                          >
                            <Stack direction={"row"}>
                              <IconButton
                                sx={{
                                  color: "white",
                                }}
                                onClick={() => {
                                  handleSingleProduct(data?._id);
                                }}
                              >
                                <VisibilityIcon color="white" />
                              </IconButton>
                              <SellingDeleteModal id={data?._id} handleCategory={handleCategory} />
                            </Stack>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <>
                    {data?.category === "real estate" ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 5,
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {data?.allProducts?.map((data, index) => {
                          return (
                            <Box
                              key={index}
                              xs={12}
                              sm={5.75}
                              md={3.75}
                              lg={2.75}
                              flexDirection={"row"}
                              sx={{
                                position: "relative",
                                "&:hover .MuiBox-root": {
                                  transition: "all 300ms ease",
                                  opacity: "1",
                                  height: "12.5rem",
                                },
                              }}
                            >
                              <Card
                                sx={{
                                  minWidth: 200,
                                  maxWidth: 200,
                                  minHeight: 200,
                                  maxHeight: 200,
                                  marginBottom: "2rem",
                                  padding: "1rem",
                                  cursor: "pointer",
                                }}
                              >
                                {data?.imagesForSale && data?.imagesForSale.length > 0 ? (
                                  <img
                                    src={data?.imagesForSale[0]?.imageUrl}
                                    alt="Image"
                                    loading="lazy"
                                    style={{
                                      minWidth: "11rem",
                                      maxWidth: "11rem",
                                      minHeight: "5rem",
                                      maxHeight: "5rem",
                                      borderRadius: "4px",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagesNotFound}
                                    alt="Image"
                                    loading="lazy"
                                    style={{
                                      minWidth: "11rem",
                                      maxWidth: "11rem",
                                      minHeight: "5rem",
                                      maxHeight: "5rem",
                                      borderRadius: "4px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                                <Box>
                                  <Typography variant="h6" color="text.secondary" gutterBottom>
                                    रू {data?.charges}
                                  </Typography>

                                  <Typography variant="h6">{data?.type}</Typography>

                                  <Grid container>
                                    <Grid item sm={8} md={8} lg={8}>
                                      <Typography sx={{ fontSize: "12px" }} color="text.secondary">
                                        {data?.address?.wardName} &nbsp; {data?.address?.wardNumber}
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                      <Typography
                                        sx={{ fontSize: "12px", textAlign: "end" }}
                                        color="text.secondary"
                                      >
                                        {dayjs(data?.updatedAt).format("DD/MM/YY")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Card>
                              <Box
                                sx={{
                                  top: 0,
                                  opacity: "0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "absolute",
                                  width: "100%",
                                  height: "87%",
                                  borderRadius: "6px",
                                  bgcolor: "rgba(0, 0, 0, 0.5)",
                                  zIndex: "1000",
                                }}
                              >
                                <Stack direction={"row"}>
                                  <IconButton
                                    sx={{
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      handleSingleProduct(data?._id);
                                    }}
                                  >
                                    <VisibilityIcon color="white" />
                                  </IconButton>
                                  <SellingDeleteModal
                                    id={data?._id}
                                    handleCategory={handleCategory}
                                  />
                                </Stack>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <>
                        {data?.category === "agricultural goods" ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 5,
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {data?.allProducts?.map((data, index) => {
                              return (
                                <Box
                                  key={index}
                                  xs={12}
                                  sm={5.75}
                                  md={3.75}
                                  lg={2.75}
                                  flexDirection={"row"}
                                  sx={{
                                    position: "relative",
                                    "&:hover .MuiBox-root": {
                                      transition: "all 300ms ease",
                                      opacity: "1",
                                      height: "12.5rem",
                                    },
                                  }}
                                >
                                  <Card
                                    sx={{
                                      minWidth: 200,
                                      maxWidth: 200,
                                      minHeight: 200,
                                      maxHeight: 200,

                                      padding: "1rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data?.images && data?.images.length > 0 ? (
                                      <img
                                        src={data?.images[0]?.imageUrl}
                                        alt="Image"
                                        loading="lazy"
                                        style={{
                                          minWidth: "11rem",
                                          maxWidth: "11rem",
                                          minHeight: "5rem",
                                          maxHeight: "5rem",
                                          borderRadius: "4px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagesNotFound}
                                        alt="Image"
                                        loading="lazy"
                                        style={{
                                          minWidth: "11rem",
                                          maxWidth: "11rem",
                                          minHeight: "5rem",
                                          maxHeight: "5rem",
                                          borderRadius: "4px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )}

                                    <Box>
                                      <Grid container>
                                        <Grid item sm={12} md={12} lg={12}>
                                          <Typography
                                            variant="h6"
                                            color="text.secondary"
                                            gutterBottom
                                          >
                                            रू {data?.price}
                                          </Typography>
                                        </Grid>

                                        <Grid item sm={12} md={12} lg={12}>
                                          <Typography variant="h6" overflow={"auto"}>
                                            {data?.title}
                                          </Typography>
                                        </Grid>

                                        <Grid item sm={8} md={8} lg={8}>
                                          <Typography
                                            sx={{ fontSize: "12px" }}
                                            color="text.secondary"
                                          >
                                            {data?.address?.wardName} &nbsp;
                                            {data?.address?.wardNumber}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={4} md={4} lg={4}>
                                          <Typography
                                            sx={{ fontSize: "12px", textAlign: "end" }}
                                            color="text.secondary"
                                          >
                                            {dayjs(data?.updatedAt).format("DD/MM/YY")}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Card>
                                  <Box
                                    sx={{
                                      top: 0,
                                      opacity: "0",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "absolute",
                                      width: "100%",
                                      height: "87%",
                                      borderRadius: "6px",
                                      bgcolor: "rgba(0, 0, 0, 0.5)",
                                      zIndex: "1000",
                                    }}
                                  >
                                    <Stack direction={"row"}>
                                      <IconButton
                                        sx={{
                                          color: "white",
                                        }}
                                        onClick={() => {
                                          handleSingleProduct(data?._id);
                                        }}
                                      >
                                        <VisibilityIcon color="white" />
                                      </IconButton>
                                      <SellingDeleteModal
                                        id={data?._id}
                                        handleCategory={handleCategory}
                                      />
                                    </Stack>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        ) : (
                          <>
                            {data?.category === "used vehicles" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 5,
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Card
                                  sx={{
                                    width: "100%",
                                    alignItems: "end",
                                    marginX: "1.5rem",
                                    marginBottom: "1rem",
                                    bgcolor: "transparent !important",
                                  }}
                                  onClick={handleNavigate}
                                >
                                  <Button
                                    sx={{
                                      paddingX: "2rem",
                                      marginRight: "0.5rem",
                                      bgcolor: "white !important",
                                    }}
                                  >
                                    Verification List
                                  </Button>
                                </Card>
                                {data?.allProducts?.map((data, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      xs={12}
                                      sm={5.75}
                                      md={3.75}
                                      lg={2.75}
                                      flexDirection={"row"}
                                      sx={{
                                        position: "relative",
                                        "&:hover .MuiBox-root": {
                                          transition: "all 300ms ease",
                                          opacity: "1",
                                          height: "12.5rem",
                                        },
                                      }}
                                    >
                                      <Card
                                        sx={{
                                          minWidth: 200,
                                          maxWidth: 200,
                                          minHeight: 200,
                                          maxHeight: 200,

                                          padding: "1rem",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {data?.images && data?.images.length > 0 ? (
                                          <img
                                            src={data?.images[0]?.imageUrl}
                                            alt="Image"
                                            loading="lazy"
                                            style={{
                                              minWidth: "11rem",
                                              maxWidth: "11rem",
                                              minHeight: "5rem",
                                              maxHeight: "5rem",
                                              borderRadius: "4px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagesNotFound}
                                            alt="Image"
                                            loading="lazy"
                                            style={{
                                              minWidth: "11rem",
                                              maxWidth: "11rem",
                                              minHeight: "5rem",
                                              maxHeight: "5rem",
                                              borderRadius: "4px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        )}
                                        <Box>
                                          <Grid container>
                                            <Grid item sm={12} md={12} lg={12}>
                                              <Typography
                                                variant="h6"
                                                color="text.secondary"
                                                gutterBottom
                                              >
                                                रू {data?.price}
                                              </Typography>
                                            </Grid>

                                            <Grid item sm={12} md={12} lg={12}>
                                              <Typography variant="h6" overflow={"auto"}>
                                                {data?.title}
                                              </Typography>
                                            </Grid>

                                            <Grid item sm={8} md={8} lg={8}>
                                              <Typography
                                                sx={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                {data?.address?.wardName} &nbsp;
                                                {data?.address?.wardNumber}
                                              </Typography>
                                            </Grid>
                                            <Grid item sm={4} md={4} lg={4}>
                                              <Typography
                                                sx={{ fontSize: "12px", textAlign: "end" }}
                                                color="text.secondary"
                                              >
                                                {dayjs(data?.updatedAt).format("DD/MM/YY")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Card>
                                      <Box
                                        sx={{
                                          top: 0,
                                          opacity: "0",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "absolute",
                                          width: "100%",
                                          height: "87%",
                                          borderRadius: "6px",
                                          bgcolor: "rgba(0, 0, 0, 0.5)",
                                          zIndex: "1000",
                                        }}
                                      >
                                        <Stack direction={"row"}>
                                          <IconButton
                                            sx={{
                                              color: "white",
                                            }}
                                            onClick={() => {
                                              handleSingleProduct(data?._id);
                                            }}
                                          >
                                            <VisibilityIcon color="white" />
                                          </IconButton>
                                          <SellingDeleteModal
                                            id={data?._id}
                                            handleCategory={handleCategory}
                                          />
                                        </Stack>
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                            ) : (
                              <>
                                {data?.category === "extracurricular class" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 5,
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {data?.allProducts?.map((data, index) => {
                                      return (
                                        <Box
                                          key={index}
                                          xs={12}
                                          sm={5.75}
                                          md={3.75}
                                          lg={2.75}
                                          flexDirection={"row"}
                                          sx={{
                                            position: "relative",
                                            "&:hover .MuiBox-root": {
                                              transition: "all 300ms ease",
                                              opacity: "1",
                                              height: "12.5rem",
                                            },
                                          }}
                                        >
                                          <Card
                                            sx={{
                                              minWidth: 200,
                                              maxWidth: 200,
                                              minHeight: 200,
                                              maxHeight: 200,

                                              padding: "1rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {data?.images && data?.images.length > 0 ? (
                                              <img
                                                src={data?.images[0]?.imageUrl}
                                                alt="Image"
                                                loading="lazy"
                                                style={{
                                                  minWidth: "11rem",
                                                  maxWidth: "11rem",
                                                  minHeight: "5rem",
                                                  maxHeight: "5rem",
                                                  borderRadius: "4px",
                                                  objectFit: "contain",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={imagesNotFound}
                                                alt="Image"
                                                loading="lazy"
                                                style={{
                                                  minWidth: "11rem",
                                                  maxWidth: "11rem",
                                                  minHeight: "5rem",
                                                  maxHeight: "5rem",
                                                  borderRadius: "4px",
                                                  objectFit: "contain",
                                                }}
                                              />
                                            )}
                                            <Box>
                                              <Grid container>
                                                <Grid item sm={12} md={12} lg={12}>
                                                  <Typography
                                                    variant="h6"
                                                    color="text.secondary"
                                                    gutterBottom
                                                  >
                                                    रू {data?.price}
                                                  </Typography>
                                                </Grid>

                                                <Grid item sm={12} md={12} lg={12}>
                                                  <Typography variant="h6" overflow={"auto"}>
                                                    {data?.title}
                                                  </Typography>
                                                </Grid>

                                                <Grid item sm={8} md={8} lg={8}>
                                                  <Typography
                                                    sx={{ fontSize: "12px" }}
                                                    color="text.secondary"
                                                  >
                                                    {data?.address?.wardName} &nbsp;
                                                    {data?.address?.wardNumber}
                                                  </Typography>
                                                </Grid>
                                                <Grid item sm={4} md={4} lg={4}>
                                                  <Typography
                                                    sx={{ fontSize: "12px", textAlign: "end" }}
                                                    color="text.secondary"
                                                  >
                                                    {dayjs(data?.updatedAt).format("DD/MM/YY")}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Card>
                                          <Box
                                            sx={{
                                              top: 0,
                                              opacity: "0",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              position: "absolute",
                                              width: "100%",
                                              height: "87%",
                                              borderRadius: "6px",
                                              bgcolor: "rgba(0, 0, 0, 0.5)",
                                              zIndex: "1000",
                                            }}
                                          >
                                            <Stack direction={"row"}>
                                              <IconButton
                                                sx={{
                                                  color: "white",
                                                }}
                                                onClick={() => {
                                                  handleSingleProduct(data?._id);
                                                }}
                                              >
                                                <VisibilityIcon color="white" />
                                              </IconButton>
                                              <SellingDeleteModal
                                                id={data?._id}
                                                handleCategory={handleCategory}
                                              />
                                            </Stack>
                                          </Box>
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                ) : (
                                  <Typography> No Product Added</Typography>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          <Box width={"96%"} display={"flex"} justifyContent={"end"}>
            {totalPage > 0 ? (
              <Pagination
                count={totalPage}
                onChange={(event, number) => handlePageChange(number)}
                color="primary"
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HomeProductSection;
