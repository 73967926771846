import { Box, Card, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import axios from "configs/axios";
import ArgonBox from "components/ArgonBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AgricultureGoods from "./Single DataDetails/AgricultureGoods";
import ExtracarricularClass from "./Single DataDetails/ExtracarricularClass";
import PartTimeJob from "./Single DataDetails/PartTimeJob";
import RealState from "./Single DataDetails/RealState";
import UsedVehicle from "./Single DataDetails/UsedVehicle";
import SellingMyItem from "./Single DataDetails/SellingMyItem";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from ".././../../redux/action/defaultActions";

const SingleProductData = () => {
  const actionDispatcher = useDispatch();
  const { Id } = useParams();
  const [data, setData] = useState("");

  // ************************** Get particular  data *******************************

  const getSelectCategory = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.get(`admin/get/product/data/by/productId/${Id}`);
      // console.log("dropdownSelectedValue=====>", res?.data);
      actionDispatcher(isLoading(false));
      setData(res?.data);
      // getLocation();
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // **************************************************************************************
  useEffect(() => {
    getSelectCategory();
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ marginTop: "4rem", minHeight: "3rem" }}>
          <ArgonBox>
            <Box>
              {data?.message === "Agricultural Data Found!" ? (
                <AgricultureGoods data={data} />
              ) : (
                <Box>
                  {data?.message === "Extracurricular class Data Found!" ? (
                    <ExtracarricularClass data={data} />
                  ) : (
                    <Box>
                      {data?.message === "Real Estate Data Found!" ? (
                        <RealState data={data} />
                      ) : (
                        <Box>
                          {data?.message === "Used Vehicle Data Found!" ? (
                            <UsedVehicle data={data} />
                          ) : (
                            <Box>
                              {data?.message === "Part Time Jobs Data Found!" ? (
                                <PartTimeJob data={data} />
                              ) : (
                                <Box>
                                  {data?.message === "Selling My Item Data Found!" ? (
                                    <SellingMyItem data={data} />
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </ArgonBox>
        </Card>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default SingleProductData;
