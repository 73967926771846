import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { isLoading, openSnackbar } from "../../../../redux/action/defaultActions";
import ArgonButton from "components/ArgonButton";

const SellingDeleteModal = ({ id, handleCategory }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // console.log("floor", id);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = async (id) => {
    dispatch(isLoading(true));
    // console.log("id pass kro", id);
    try {
      const res = await axios.delete(`admin/delete/product/by/productId/${id}`);
      // console.log("responseDekho", res);
      if (res?.data?.success === true) {
        dispatch(isLoading(false));
        dispatch(openSnackbar(res?.data?.message, "success"));
        handleOpen();
        handleCategory("all");
      }
    } catch (error) {
      dispatch(isLoading(false));
      dispatch(openSnackbar(error?.response?.data?.message, "error"));
      console.log(error.message);
    }
  };
  return (
    <>
      <IconButton onClick={handleOpen} sx={{ color: "white" }}>
        <DeleteIcon color="white" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography id="modal-modal-title" variant="h6">
            Delete Product
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure to want to delete this Product ?
          </Typography>
          <ArgonButton variant="contained" onClick={handleOpen} sx={{ mt: 2, marginRight: "1rem" }}>
            Cancel
          </ArgonButton>
          <ArgonButton variant="contained" onClick={() => handleDelete(id)} sx={{ mt: 2 }}>
            Delete
          </ArgonButton>
        </Box>
      </Modal>
    </>
  );
};
SellingDeleteModal.propTypes = {
  id: PropTypes.string,
  handleCategory: PropTypes.func,
};

export default SellingDeleteModal;
