import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { Box, Button, ButtonGroup, Card, Grid, Input, TextField, Typography } from "@mui/material";
import axios from "configs/axios";
import SellingItemModal from "./SellingItemModal";
import PartTimeJob from "./PartTimeJob";
import AgriculturalGoods from "./AgriculturalGoods";
import UsedVehicle from "./UsedVehicle";
import RealState from "./RealState";
import ExtracurricularClass from "./ExtracurricularClass";

export default function MainListManagement() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const actionDispatcher = useDispatch();
  const [optionData, setOptionData] = useState([]);
  const [category, setCategory] = useState("");

  // ************************** Get main category data of Dropdown Section *******************************

  const getMainCategory = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.get(`admin/get/main/categories`);
      // console.log("resssmaincategory=====>", res?.data?.allMainCategories);
      actionDispatcher(isLoading(false));
      setOptionData(res?.data?.allMainCategories);
      // getLocation();
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };

  // **************************************************************************************
  // ---------------------------------------------------
  const toggleSellingItemModal = () => setOpen(!open);
  // ---------------------------------------------------

  const [open, setOpen] = useState(false);
  const handleOpen = (category) => {
    setCategory(category);
    // setOpen(!open);
    toggleSellingItemModal();
  };

  useEffect(() => {
    // getProduct();
    getMainCategory();
    // setCategory("");
    // getSelectCategory("selling my item");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Grid container spacing={2} m={2}>
          <Grid item xs={12}>
            <Typography color={"primary"}>Add List Management</Typography>
          </Grid>
          {optionData?.map((option, index) => {
            return (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Button variant="contained" onClick={() => handleOpen(option?.category)}>
                  {option?.category}
                </Button>

                {category === "selling my item" ? (
                  <SellingItemModal
                    open={open}
                    setOpen={setOpen}
                    category={category ? category : ""}
                  />
                ) : (
                  <>
                    {category === "part time job" ? (
                      <PartTimeJob
                        open={open}
                        setOpen={setOpen}
                        category={category ? category : ""}
                      />
                    ) : (
                      <>
                        {category === "agricultural goods" ? (
                          <AgriculturalGoods
                            open={open}
                            setOpen={setOpen}
                            category={category ? category : ""}
                          />
                        ) : (
                          <>
                            {category === "used vehicles" ? (
                              <UsedVehicle
                                open={open}
                                setOpen={setOpen}
                                category={category ? category : ""}
                              />
                            ) : (
                              <>
                                {category === "real estate" ? (
                                  <RealState
                                    open={open}
                                    setOpen={setOpen}
                                    category={category ? category : ""}
                                  />
                                ) : (
                                  <>
                                    {category === "extracurricular class" ? (
                                      <ExtracurricularClass
                                        open={open}
                                        setOpen={setOpen}
                                        category={category ? category : ""}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </DashboardLayout>
  );
}
