// import React from "react";

// const ReportBugsMain = () => {
//   return <div></div>;
// };

// export default ReportBugsMain;
import { Box, Card, CircularProgress } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "../../../examples/Tables/Table";
import React, { useEffect, useState } from "react";
import ReportsBugsTableData from "./ReportsBugsTableData";
import { useSelector } from "react-redux";

const ReportBugsMain = () => {
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const search = useSelector((state) => state.search);

  const handleChangePage = (event, newPage) => {
    // console.log("pageChange===>", event);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("====", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // useEffect(() => {
  //   ReportsBugsTableData(page, rowsPerPage).then(({ columns, rows, noOfUsers, noOfPages }) => {
  //     setTableData({ columns, rows, noOfUsers, noOfPages });
  //   });
  // }, [page, rowsPerPage]);
  useEffect(() => {
    ReportsBugsTableData(page, rowsPerPage, search.query).then(
      ({ columns, rows, noOfReportedBugs, noOfPages }) => {
        setTableData({ columns, rows, noOfReportedBugs, noOfPages });
      }
    );
  }, [page, rowsPerPage, search.query]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <ArgonTypography variant="h5">Reported Bugs</ArgonTypography>
          </ArgonBox>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {tableData ? (
              <Table
                columns={tableData?.columns}
                rows={tableData?.rows}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                noOfPages={tableData.noOfPages ? tableData.noOfPages : 0}
                noOfUsers={tableData.noOfReportedBugs ? tableData.noOfReportedBugs : 0}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </ArgonBox>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ReportBugsMain;
