/* eslint-disable react/prop-types */
// Gurash Dahboard MUI components
import React from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";

// Images
import { useState } from "react";
import axios from "configs/axios";
import AddvertisementSingle from "./AddvertisementSingle";

function Author({ image, name, email, data }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  //   // ****************************Single UsER Details Section*********************************
  const handleEditClick = async (advertisementId) => {
    try {
      const res = await axios.get(
        `admin/get/advertisement/detail/by/advertisementId/${advertisementId}`
      );
      // console.log("AddvertisementSingleUsers===>", res?.data?.advertisementDetail);
      handleOpen();
      return res?.data?.advertisementDetail;
      // return res.data.allUsers;
    } catch (error) {
      console.log("error===>", error);
    }
  };
  //   // ****************************************************************************************
  return (
    <>
      <ArgonBox
        display="flex"
        alignItems="center"
        px={1}
        py={0.5}
        onClick={() => handleEditClick(data?._id)}
        sx={{ cursor: "pointer" }}
      >
        <ArgonBox mr={2}>
          <ArgonAvatar src={image} alt={name} size="md" variant="rounded" bgColor={"light"} />
        </ArgonBox>
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="button" fontWeight="medium">
            {name}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {email}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <AddvertisementSingle open={open} handleOpen={handleOpen} data={data} />
    </>
  );
}

function Function({ job }) {
  return (
    <>
      <ArgonBox display="flex" flexDirection="column">
        {/* <ArgonTypography variant="caption" fontWeight="medium" color="text">
          {job?.advertisementType}
        </ArgonTypography> */}
        <ArgonTypography variant="caption" color="secondary">
          {job?.advertisementType}
        </ArgonTypography>
      </ArgonBox>
    </>
  );
}
// ****************************************************************************************
const Type = ({ data }) => {
  return (
    <ArgonBadge
      variant="gradient"
      badgeContent={data?.status}
      color={
        data?.status === "approved"
          ? "success"
          : `${
              data?.status === "active"
                ? "info"
                : `${
                    data?.status === "completed"
                      ? "dark"
                      : `${data?.status === "pending" ? "warning" : "error"}`
                  }`
            }`
      }
      size="xs"
      container
      cursor="pointer"
    />
  );
};

const WebsitePost = ({ data }) => {
  return (
    <>
      {data?.advertisementType === "website" ? (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
          {/* {data?.websiteUrl} */}
          <a href={data?.websiteUrl} target="_blank" rel="noopener noreferrer">
            {data?.websiteUrl}
          </a>
        </ArgonTypography>
      ) : (
        "--"
      )}
    </>
  );
};

const PaymentType = ({ data }) => {
  return (
    <ArgonBadge
      variant="gradient"
      badgeContent={data?.paymentStatus}
      color={
        data?.paymentStatus === "paid"
          ? "success"
          : `${data?.paymentStatus === "rejected" ? "error" : "warning"}`
      }
      size="xs"
      container
      cursor="pointer"
    />
  );
};

// ****************************All USER Details Section*********************************
const getUserData = async (page, rowsPerPage, search) => {
  try {
    const res = await axios.get(
      `admin/get/all/advertisement/requests?limitPerPage=${rowsPerPage}&pageNo=${
        page + 1
      }&search=${search}`
    );

    return {
      advertisementData: res.data?.allAdvertisements,
      noOfAdvertisements: res.data.noOfAdvertisements,
      noOfPages: res.data.noOfPages,
    };
  } catch (error) {
    console.log("error===>", error);
    throw error;
  }
};
// ****************************************************************************************

const AdvertisementTableData = async (page, rowsPerPage, search) => {
  const { advertisementData, noOfAdvertisements, noOfPages } = await getUserData(
    page,
    rowsPerPage,
    search
  );

  // const advertisementData = await getUserData();
  // const reload = await getUserData();

  return {
    columns: [
      { name: "author", align: "left" },
      { name: "payment Status", align: "center" },
      { name: "Type", align: "left" },
      { name: "website URL", align: "center" },
      { name: "status", align: "center" },
      // { name: "action", align: "center" },
    ],
    rows: advertisementData?.map((data) => ({
      author: (
        <Author
          image={data?.advertisementImage?.imageUrl}
          name={data?.user?.userName}
          email={data?.advertisementCategory}
          data={data}
        />
      ),
      "payment Status": <PaymentType data={data} />,
      Type: (
        <Function
          job={data}
          //  org="Organization"
        />
      ),

      "website URL": <WebsitePost data={data} />,
      status: <Type data={data} />,
    })),
    noOfPages: noOfPages,
    noOfAdvertisements: noOfAdvertisements,
  };
};

export default AdvertisementTableData;
