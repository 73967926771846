import { Box, Button, CardContent, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import ArgonInput from "components/ArgonInput";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

// Style of Modal section set in center
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 4,
  p: 2,
};
// ******************************

const ExtracurricularClass = ({ open, setOpen, category }) => {
  const actionDispatcher = useDispatch();
  const [subCategory, setSubCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const handleClose = () => {
    setOpen(!open);
  };

  //   // ************************** SHandle Submit Add category here  *******************************

  const handleFormSubmit = async (event) => {
    actionDispatcher(isLoading(true));
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("mainCategoryName", category);
      formData.append("categoryName", subCategory);
      formData.append("image", selectedFile);

      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.post("admin/add/categories", formData);
      actionDispatcher(isLoading(false));
      setSubCategory("");
      setSelectedFile(null);
      setPreviewURL(null);
      actionDispatcher(openSnackbar(response?.data?.message, "success"));
      handleClose();
      // console.log("Category updated successfully:", response.data);
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.error("Error uploading image:", error);
    }
  };
  //   // **************************************************************************************

  //   // ************************** Store selected dropdown value into a state  *******************************

  const handleChangeSub = async (event) => {
    setSubCategory(event.target.value);
  };

  //   // **************************************************************************************

  //   // ************************** Store selected Images into a state  *******************************

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewURL(URL.createObjectURL(file));
  };

  //   // **************************************************************************************
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
            backgroundColor: "rgb(0 0 0 / 15%)",
          },
        }}
        // sx={{ backgroundColor: "rgba(255,255,255,0.8) !important" }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: "1.5rem" }}>
            <Typography variant="h5">{category.toUpperCase()}</Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
          <CardContent display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {/* <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6"> Category</Typography>
                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    value={category}
                    disable
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Management Fee Category</Typography>
                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    onChange={handleChangeSub}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6"> Separate Management Fee Category</Typography>

                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    // type="file"
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6"> Facilities</Typography>

                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    // type="file"
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6"> Banefit of House</Typography>

                  <ArgonInput
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                    // type="file"
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Grid>
                {previewURL && (
                  <img
                    src={previewURL}
                    alt="Selected"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      objectFit: "contain",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  />
                )}
                <Grid item xs={12} sm={12} textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    bgcolor="#4bcfde"
                    sx={{ color: "white !important" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form> */}
            <Typography>Nothing here to add</Typography>
          </CardContent>
        </Box>
      </Modal>
    </>
  );
};
ExtracurricularClass.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  category: PropTypes.string,
};

export default ExtracurricularClass;
