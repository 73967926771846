import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Button, Card } from "@mui/material";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import ArgonTypography from "components/ArgonTypography";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

// Import the CSS file
import "./manualStyle.css";
import ArgonButton from "components/ArgonButton";
import { Outlet, useLocation } from "react-router-dom";

function TermsAndCondition() {
  const location = useLocation();

  const actionDispatcher = useDispatch();
  const [editorData, setEditorData] = useState("");

  // ****************************************************
  const storeSpecificData = () => {
    // Split the string into an array of characters
    const characters = location?.pathname?.split("/");

    // Filter out specific characters based on a condition
    const filteredData = characters[characters.length - 1];
    // console.log(filteredData);
    return filteredData;

    // Update state with filtered data
  };
  // **********************************************************************

  // ************************** post data Through Api stored into a state   *******************************

  const getSelectCategory = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.post(`admin/add/terms/and/conditions`, {
        type: storeSpecificData(),
        termsAndConditions: editorData,
      });
      // console.log("dropdownSelectedValue=====>", res);
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // *************************************************************************************************

  // ********************Get poicy data  function********************
  const getPolicyData = async () => {
    storeSpecificData();
    {
      if (storeSpecificData()) {
        try {
          const res = await axios.get(`admin/get/terms/and/conditions/${storeSpecificData()}`);
          // console.log("dropdownSelectedValue=====>", res);
          setEditorData(res?.data?.data?.termsConditions);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        actionDispatcher(openSnackbar("Please select the type", "error"));
      }
    }
  };
  // ***************************************************************************************

  // ******************************Stored VAlue inside the state**********************************
  const handleEditorChange = (editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  // *********************************************************************************************
  useEffect(() => {
    getPolicyData(editorData);
  }, [storeSpecificData()]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card className="container">
          <form onSubmit={getSelectCategory}>
            <Box className="title">
              <ArgonTypography variant={"h5"} sx={{ textTransform: "capitalize" }}>
                {storeSpecificData()} Terms And Condition
              </ArgonTypography>
            </Box>
            <CKEditor
              editor={Editor}
              // config={editorConfiguration}
              config={{
                ckfinder: {
                  // Upload the images to the server using the CKFinder QuickUpload command
                  // You have to change this address to your server that has the ckfinder php connector
                  uploadUrl: "admin/save/gurash/saving/image",
                },
              }}
              data={editorData ? editorData : ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => handleEditorChange(editor)}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              <ArgonButton variant="gradient" color="success" sx={{ margin: "1rem" }} type="submit">
                Submit
              </ArgonButton>
              <Outlet />
            </Box>
          </form>
          <Footer />
        </Card>
      </DashboardLayout>
    </>
  );
}

TermsAndCondition.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TermsAndCondition;
