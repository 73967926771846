// export default BusinessTableData
/* eslint-disable react/prop-types */
// Gurash Dahboard MUI components
import React, { useEffect } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";

// Images

import { useState } from "react";
import axios from "configs/axios";
import dayjs from "dayjs";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SingleUserDetails from "../SingleUserDetails";

function Author({ image, name, email, data }) {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(true);
  // console.log("dataUserId=====>", data?.user?._id);

  const handleOpen = () => {
    setOpen(!open);
    setTransaction(!transaction);
  };
  return (
    <>
      <ArgonBox
        display="flex"
        alignItems="center"
        px={1}
        py={0.5}
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
      >
        <ArgonBox mr={2}>
          <ArgonAvatar src={image} alt={name} size="md" variant="rounded" bgColor={"light"} />
        </ArgonBox>
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="button" fontWeight="medium">
            {name}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {email}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      {open && <SingleUserDetails open={open} handleOpen={handleOpen} userId={data?.user?._id} />}
    </>
  );
}

function Function({ job }) {
  return (
    <>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </ArgonTypography>
        {/* <ArgonTypography variant="caption" color="secondary">
          {org}
        </ArgonTypography> */}
      </ArgonBox>
    </>
  );
}
// ****************************************************************************************
const Type = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      {data?.businessProfile === null ? (
        "---"
      ) : (
        <ArgonTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          onClick={() => navigate(`/businessProfile/${data?.user?._id}`)}
        >
          <ArgonBadge
            variant="gradient"
            badgeContent="Business"
            color="success"
            size="xs"
            container
            cursor="pointer"
          />
        </ArgonTypography>
      )}

      {/*  */}
    </>
  );
};

// ****************************All USER Details Section*********************************
const getUserData = async (page, rowsPerPage, search) => {
  // actionDispatcher(isLoading(true));
  try {
    const res = await axios.get(
      `admin/get/all/business/profiles?limitPerPage=${rowsPerPage}&pageNo=${
        page + 1
      }&search=${search}`
    );
    // console.log("allUsers===>", res?.data);
    return {
      data: res.data.allBusinessProfiles,
      noOfUsers: res.data.noOfBusinessProfiles,
      noOfPages: res.data.noOfPages,
    };
  } catch (error) {
    console.log("error===>", error);
  }
};
// ****************************************************************************************

const BusinessTableData = async (page, rowsPerPage, search) => {
  const { data = {}, noOfUsers, noOfPages } = await getUserData(page, rowsPerPage, search);
  // console.log("=====>", data);
  return {
    columns: [
      { name: "author", align: "left" },
      { name: "Contact", align: "left" },
      { name: "Created_At", align: "center" },
      { name: "Business Profile", align: "center" },
      { name: "Business Name", align: "center" },
    ],
    rows: data?.map((v) => ({
      author: (
        <Author
          image={v?.images[0]?.imageUrl}
          name={v?.user?.userName}
          email={v?.user?.email}
          data={v}
        />
      ),
      Contact: (
        <Function
          job={v.countryCode + " " + v?.contactNo}
          //  org="Organization"
        />
      ),

      Created_At: (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
          {dayjs(v?.createdAt).format("DD/MM/YY")}
        </ArgonTypography>
      ),
      "Business Profile": <Type data={v} />,
      "Business Name": (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
          {v?.businessName}
        </ArgonTypography>
      ),
    })),
    noOfPages: noOfPages,
    noOfUsers: noOfUsers,
  };
};

export default BusinessTableData;
