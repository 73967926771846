/* eslint-disable react/prop-types */
// Gurash Dahboard MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Images
import { useState } from "react";
import axios from "configs/axios";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import VerificationUserDetails from "./VerificationUserDetails";

function Author({ image, name, email }) {
  // user/get/all/users'
  return (
    <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
      <ArgonBox mr={2}>
        <ArgonAvatar src={image} alt={name} size="md" variant="rounded" />
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="button" fontWeight="medium">
          {name}
        </ArgonTypography>
        <ArgonTypography variant="caption" color="secondary">
          {email}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

function Function({ job }) {
  return (
    <>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </ArgonTypography>
        {/* <ArgonTypography variant="caption" color="secondary">
          {org}
        </ArgonTypography> */}
      </ArgonBox>
    </>
  );
}

const getUserData = async (page, rowsPerPage, search) => {
  // actionDispatcher(isLoading(true));
  try {
    const res = await axios.get(
      `admin/get/all/verification/details?limitPerPage=${rowsPerPage}&pageNo=${
        page + 1
      }&search=${search}`
    );
    // console.log("verifications===>", res?.data);

    return {
      data: res?.data?.allVerification,
      noOfVerificationRequests: res.data.noOfVerificationRequests,
      noOfPages: res.data.noOfPages,
    };
  } catch (error) {
    console.log("error===>", error);
  }
};

const Action = ({ data }) => {
  const [openVeri, setOpenVeri] = useState(false);
  const handleOpen = () => setOpenVeri(!openVeri);

  // ****************************Single UsER Details Section*********************************
  const handleEditClick = async (userId) => {
    try {
      const res = await axios.get(`admin/get/single/verification/detail/${userId}`);
      // console.log("SingleUsers===>", res?.data);
      handleOpen();
      return res?.data;
    } catch (error) {
      console.log("error===>", error);
    }
  };
  // ****************************************************************************************

  return (
    <>
      <ArgonTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={() => handleEditClick(data?._id)}
      >
        <Button>
          <MoreVertIcon fontSize="large" />
        </Button>
      </ArgonTypography>
      <VerificationUserDetails
        open={openVeri}
        handleOpen={handleOpen}
        data={data}
        // rerenderData={rerenderData}
      />
    </>
  );
};

const VerificationTable = async (page, rowsPerPage, search) => {
  const {
    data = {},
    noOfVerificationRequests,
    noOfPages,
  } = await getUserData(page, rowsPerPage, search);
  console.log("VerificationDatt--=====>", data);
  return {
    columns: [
      { name: "Name", align: "left" },
      // { name: "Contact", align: "left" },
      { name: "CreatedAt", align: "center" },
      { name: "LicenceNo", align: "left" },
      { name: "VehicleNo", align: "center" },
      { name: "status", align: "center" },
      { name: "action", align: "center" },
    ],
    rows: data?.map((data) => ({
      Name: (
        <>
          {data?.category === "used vehicles" ? (
            <Author
              image={data?.licenceSelfieImage?.imageUrl}
              name={data?.ownerName}
              email={data?.user?.email}
              fontSize="1rem"
            />
          ) : (
            <Author
              image={data?.citizenshipSelfieWithCardPhoto?.imageUrl}
              name={data?.user?.userName}
              email={data?.user?.email}
              fontSize="1rem"
            />
          )}
        </>
      ),
      // Contact: (
      //   <Function
      //     job={data?.contactNo}
      //     //  org="Organization"
      //   />
      // ),

      CreatedAt: (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium" fontSize="1rem">
          {dayjs(data?.createdAt).format("DD/MM/YY")}
        </ArgonTypography>
      ),
      LicenceNo: (
        <>
          {data?.category === "used vehicles" ? (
            <ArgonTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
              fontSize="1rem"
            >
              {data?.licenseNumber}
            </ArgonTypography>
          ) : (
            <ArgonTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
              fontSize="1rem"
            >
              {data?.citizenshipCardnumber}
            </ArgonTypography>
          )}
        </>
      ),
      VehicleNo: (
        <>
          {data?.category === "used vehicles" ? (
            <ArgonTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
              fontSize="1rem"
            >
              {data?.vehicleNumber}
            </ArgonTypography>
          ) : (
            <ArgonTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
              fontSize="1rem"
            >
              -
            </ArgonTypography>
          )}
        </>
      ),
      status: (
        <ArgonBadge
          variant="gradient"
          badgeContent={data?.status}
          color={
            data?.status === "pending"
              ? "warning"
              : data?.status === "approved"
                ? "success"
                : "error"
          }
          fontSize="1rem"
          container
        />
      ),
      action: <Action data={data} fontSize="1rem" />,
    })),
    noOfPages: noOfPages,
    noOfVerificationRequests: noOfVerificationRequests,
  };
};

export default VerificationTable;
