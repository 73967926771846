/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Gurash Dahboard MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
// import ArgonProgress from "componen ts/ArgonProgress";
import axios from "configs/axios";
import { useState } from "react";
import "../TransactionManagement/ScreenshotFull.css";
import ArgonAvatar from "components/ArgonAvatar";
import { Box, Modal } from "@mui/material";

import SingleUserDetails from "../UserDetails/SingleUserDetails";
import ArgonProgress from "components/ArgonProgress";
import dayjs from "dayjs";

// Modal csss image section
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Author({ data }) {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(true);
  const handleOpen = () => {
    setOpen(!open);
    setTransaction(!transaction);
  };

  return (
    <>
      <ArgonBox
        display="flex"
        alignItems="center"
        px={1}
        py={0.5}
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
      >
        {/* <ArgonBox mr={2}>
          <ArgonAvatar src={image} alt={name} size="md" variant="rounded" />
        </ArgonBox> */}
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="button" fontWeight="medium">
            {data?.user?.userName}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {data?.user?.email}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      {/* {open && (
        <SingleUserDetails
          open={open}
          handleOpen={handleOpen}
          userId={data?._id}
          transaction={transaction}
        />
      )} */}
    </>
  );
}

function Description({ description }) {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
    setTransaction(!transaction);
  };

  return (
    <>
      <ArgonBox display="flex" alignItems="center" px={1} py={0.5} sx={{ cursor: "pointer" }}>
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="button" fontWeight="medium">
            {description}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </>
  );
}

function Completion({ data }) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <ArgonBox
        sx={{
          width: "2rem",
          height: "2rem",
          position: "relative",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
          src={data?.bugImage?.imageUrl}
          alt="bugs&report"
          onClick={toggleModal}
        />
      </ArgonBox>
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ArgonBox className="modal-overlay" onClick={toggleModal}>
              <ArgonBox className="modal-content">
                <img src={data?.bugImage?.imageUrl} alt="bugs&report" />
              </ArgonBox>
            </ArgonBox>
          </Box>
        </Modal>
      )}
    </>
  );
}

// // ****************************All USER Details Section*********************************
const getUserData = async (page, rowsPerPage, search) => {
  // actionDispatcher(isLoading(true));
  try {
    const res = await axios.get(
      `admin/get/all/reported/bugs?limitPerPage=${rowsPerPage}&pageNo=${page + 1}&search=${search}`
    );
    console.log("Bugs===>", res?.data);
    // return res?.data;
    return {
      ReportData: res.data?.allData,
      noOfReportedBugs: res.data.noOfReportedBugs,
      noOfPages: res.data.noOfPages,
    };
  } catch (error) {
    console.log("error===>", error);
  }
};
// // ****************************************************************************************

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

const ReportsBugsTableData = async (page, rowsPerPage, search) => {
  const { ReportData, noOfReportedBugs, noOfPages } = await getUserData(page, rowsPerPage, search);

  //   console.log("Alltransaction ====>", ReportData);
  return {
    columns: [
      { name: "Author", align: "left" },
      { name: "Reported Id", align: "left" },
      { name: "Description", align: "left" },
      { name: "CreatedAt", align: "left" },
      { name: "image", align: "center" },
      { name: "action", align: "center" },
    ],
    rows: ReportData?.map((data) => ({
      //   user: [`${data?.user?.image?.imageUrl}`, `${data?.user?.userName}`],
      Author: <Author data={data} />,
      "Reported Id": (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
          {data?._id}
        </ArgonTypography>
      ),
      Description: <Description description={data?.description} />,
      CreatedAt: (
        <ArgonTypography variant="caption" color="secondary" fontWeight="medium">
          {dayjs(data?.createdAt).format("DD/MM/YY")}
        </ArgonTypography>
      ),
      //   completion: <Completion value={60} color="info" />,
      image: <Completion data={data} />,
      action,
    })),
    noOfPages: noOfPages,
    noOfReportedBugs: noOfReportedBugs,
  };
};

export default ReportsBugsTableData;
