/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Gurash Dahboard MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
// import ArgonProgress from "componen ts/ArgonProgress";
import axios from "configs/axios";
import { useState } from "react";
import "./ScreenshotFull.css";
import ArgonAvatar from "components/ArgonAvatar";
import { Box, Grid, Modal, Typography } from "@mui/material";
import SingleUserDetails from "../UserDetails/SingleUserDetails";
import dayjs from "dayjs";
import SingleTransaction from "./SingleTransaction";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

// Modal csss image section
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadious: "5px",
};

function Author({ image, name, data }) {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
    setTransaction(!transaction);
  };

  return (
    <>
      <ArgonBox
        display="flex"
        alignItems="center"
        px={1}
        py={0.5}
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
      >
        <ArgonBox mr={2}>
          <ArgonAvatar src={image} alt={name} size="md" variant="rounded" bgColor={"light"} />
        </ArgonBox>
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="button" fontWeight="medium">
            {name}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {data?.user?.email}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <SingleUserDetails
        open={open}
        handleOpen={handleOpen}
        userId={data?.user?._id}
        transaction={transaction}
      />
    </>
  );
}

function Completion({ data }) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <ArgonBox
        sx={{
          width: "2rem",
          height: "2rem",
          position: "relative",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
          src={data?.paymentImage?.imageUrl}
          alt="TransactionScreenshot"
          onClick={toggleModal}
        />
      </ArgonBox>
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ArgonBox className="modal-overlay" onClick={toggleModal}>
              <ArgonBox className="modal-content">
                <img src={data?.paymentImage?.imageUrl} alt="TransactionScreenshotFull" />
              </ArgonBox>
            </ArgonBox>
          </Box>
        </Modal>
      )}
    </>
  );
}

// // ****************************All USER Details Section*********************************
const getUserData = async (page, rowsPerPage, search) => {
  // actionDispatcher(isLoading(true));

  try {
    const res = await axios.get(
      // `admin/get/all/users?limitPerPage=${rowsPerPage}&pageNo=${page + 1}`
      `admin/get/all/advertisement/payment/transactions?limitPerPage=${rowsPerPage}&pageNo=${
        page + 1
      }&search=${search}`
    );
    // console.log("TransactionApi===>", res?.data);
    // return res?.data;
    return {
      TransactionData: res.data.allTransactions,
      noOfTransactions: res.data.noOfTransactions,
      noOfPages: res.data.noOfPages,
    };
  } catch (error) {
    console.log("error===>", error);
  }
};
// // ****************************************************************************************

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);
const Advertisement = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [SingleData, setSingleData] = useState();
  const handleOpen = () => setOpen(!open);

  //   // ****************************Single UsER Details Section*********************************
  const handleStatus = async (userId) => {
    try {
      const res = await axios.get(`admin/get/advertisement/detail/by/advertisementId/${userId}`);
      // console.log("AddvertisementSingleUsers===>", res?.data?.advertisementDetail);
      handleOpen();
      setSingleData(res?.data?.advertisementDetail);
      // return res.data.allUsers;
    } catch (error) {
      console.log("error===>", error);
    }
  };
  return (
    <>
      <ArgonTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{
          textTransform: "capitalize",
          cursor: "pointer",
          "&:hover": {
            color: "red", // Change color on hover
            textDecoration: "underline",
          },
        }}
        onClick={() => handleStatus(data?.advertisement?._id)}
      >
        {data?.advertisement?.advertisementType}
      </ArgonTypography>
      <SingleTransaction open={open} handleOpen={handleOpen} data={SingleData} />
    </>
  );
};

const Type = ({ data }) => {
  const actionDispatcher = useDispatch();
  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState("");

  // console.log("InsideTransactionData====>", data);

  // ***********************************Open Modal State change****************************************
  const handleClose = () => {
    if (data?.status === "pending") {
      setStatusOpen(!statusOpen);
    }
  };
  // ***************************************************************************

  //   // **************Advertisement UsER  Status Action Section*******************
  const handleEditClick = async (userId) => {
    // actionDispatcher(isLoading(true));

    if (data?.status === "verified") {
      // Perform the action when status is pending
      console.log("Status is verified, action not triggered");
    } else {
      // Do nothing if status is verified
      try {
        const res = await axios.put(`admin/update/and/verify/payment`, {
          paymentId: userId,
          paymentTransactionStatus: status,
        });
        actionDispatcher(openSnackbar(res?.data?.message, "success"));
        actionDispatcher(isLoading(false));
        handleClose();
      } catch (error) {
        actionDispatcher(isLoading(false));
        console.log("error===>", error);
        actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      }
    }
  };

  //   // ********************************************************************
  return (
    <>
      <ArgonBadge
        variant="gradient"
        badgeContent={data?.status === "verified" ? "Verified" : "Pending"}
        color={data?.status === "verified" ? "success" : "warning"}
        size="xs"
        sx={{ cursor: data?.status === "verified" ? "none" : "pointer" }}
        onClick={() => {
          handleClose(data?._id);
        }}
      />

      <Modal
        open={statusOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Verification
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h4" color={"secondary"}>
            Verify this Payment to run this Advertisement!
          </Typography>
          <ArgonButton
            alignItems={"center"}
            variant="gradient"
            color="success"
            sx={{ margin: "1rem" }}
            onClick={() => {
              handleEditClick(data?._id, setStatus("rejected"));
            }}
          >
            Rejected
          </ArgonButton>
          <ArgonButton
            variant="gradient"
            color="success"
            sx={{ margin: "1rem", alignItems: "center " }}
            onClick={() => {
              handleEditClick(data?._id, setStatus("verified"));
            }}
          >
            Verify
          </ArgonButton>
        </Box>
      </Modal>
    </>
  );
};

const TransactionTableData = async (page, rowsPerPage, search) => {
  const {
    TransactionData = {},
    noOfTransactions,
    noOfPages,
  } = await getUserData(page, rowsPerPage, search);
  // const TransactionTableData = async () => {
  //   const TransactionData = await getUserData();
  //   const reload = getUserData;

  return {
    columns: [
      { name: "user", align: "left" },
      { name: "amount", align: "left" },
      { name: "Trans Date", align: "left" },
      { name: "Advertisement Type", align: "left" },
      { name: "Transaction Img", align: "center" },
      { name: "Status", align: "center" },
    ],
    rows: TransactionData?.map((data) => ({
      //   user: [`${data?.user?.image?.imageUrl}`, `${data?.user?.userName}`],
      user: <Author image={data?.user?.image?.imageUrl} name={data?.user?.userName} data={data} />,
      amount: (
        <ArgonTypography variant="button" color="text" fontWeight="medium">
          रू{data?.amount}
        </ArgonTypography>
      ),
      "Trans Date": (
        <ArgonTypography variant="caption" color="secondary">
          {dayjs(data?.createdAt).format("DD/MM/YY")}
        </ArgonTypography>
      ),
      "Advertisement Type": <Advertisement data={data} />,
      //   completion: <Completion value={60} color="info" />,
      "Transaction Img": <Completion data={data} />,
      Status: <Type data={data} />,
    })),
    noOfPages: noOfPages,
    noOfTransactions: noOfTransactions,
  };
};

export default TransactionTableData;
