import Axios from "axios";
import Cookies from "js-cookie";

// Create an Axios instance with default settings
const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // Set a timeout for requests (10 seconds)
  headers: {
    "Content-Type": "application/json", // Set default content type
    Accept: "application/json", // Set default accept type
  },
});

// Add a request interceptor (optional)
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("authToken");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the request error
    return Promise.reject(error);
  }
);

// Add a response interceptor (optional)
// axios.interceptors.response.use(
// 	(response) => {
// 		// Handle the response data as needed
// 		return response.data; // This will return only the data, not the full response
// 	},
// 	(error) => {
// 		// Handle the response error
// 		return Promise.reject(error);
// 	}
// );

// Export the configured axios instance
export default axios;
