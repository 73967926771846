import React, { useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddPaymentModal = ({ open, handleOpen }) => {
  const actionDispatcher = useDispatch();
  const initialState = {
    accountNumber: "",
    accountHolderName: "",
    bankName: "",
    ifscCode: null,
    file: null, // Initialize as null
    qrCodeImageUrl: "", // Added to store the image URL for preview
  };
  const [bankDetails, setBankDetails] = useState(initialState);

  // ***********************  Stored Value inside textBox  ***********************************

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "qrImage") {
      const Imgfile = files[0];
      const imageUrl = URL.createObjectURL(Imgfile);
      setBankDetails((prevState) => ({
        ...prevState,
        file: Imgfile,
        qrCodeImageUrl: imageUrl,
      }));
    } else {
      setBankDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // *********************************************************

  //   // ****************************Advertisement Budget Action Section*********************************
  const UpdatePayment = async () => {
    // actionDispatcher(isLoading(true));
    try {
      const res = await axios.post(`admin/add/and/update/bank/details/and/QR/Code`, {
        bankName: bankDetails?.bankName,
        accountNo: bankDetails?.accountNumber,
        accountHolderName: bankDetails?.accountHolderName,
        ifscCode: bankDetails?.ifscCode === "" ? null : bankDetails?.ifscCode,
        file: bankDetails?.qrImage,
      });

      console.log("updateBank details===>", res?.data);
      // setBankDetails(initialState);
      // actionDispatcher(isLoading(false));
      // handleOpen();
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      // actionDispatcher(isLoading(false));
      console.log("error===>", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };

  // ***************************************************************************************************
  //   useEffect(() => {
  //     setInputValue(data);
  //   }, [data]);
  //

  return (
    <>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Set Payment Details
            </Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>

          <Grid container px={3} py={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontSize: "0.9rem" }}>Add Account Number</Typography>
              <ArgonInput
                type="number"
                name="accountNumber"
                value={bankDetails?.accountNumber}
                onChange={handleChange}
                placeholder="Enter Account Number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontSize: "0.9rem" }}>Account Holder Name</Typography>
              <ArgonInput
                type="text"
                name="accountHolderName"
                value={bankDetails?.accountHolderName}
                onChange={handleChange}
                placeholder="Enter Name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontSize: "0.9rem" }}>Bank Name</Typography>
              <ArgonInput
                type="text"
                name="bankName"
                value={bankDetails?.bankName}
                onChange={handleChange}
                placeholder="Enter Account Number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontSize: "0.9rem" }}>IFSC Code</Typography>
              <ArgonInput
                type="text"
                name="ifscCode"
                value={bankDetails?.ifscCode}
                onChange={handleChange}
                placeholder="Enter Account Number"
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={12} md={12}>
              <Grid item xs={8} sm={8} md={7}>
                <Typography sx={{ fontSize: "0.9rem" }}>Add QR Image </Typography>
                <ArgonInput type="file" name="qrImage" accept="image/*" onChange={handleChange} />
              </Grid>
              {bankDetails?.qrCodeImageUrl && (
                <Grid item xs={4} sm={4} md={5} sx={{ marginTop: "0.5rem" }}>
                  <img
                    src={bankDetails.qrCodeImageUrl}
                    alt="QR Code Preview"
                    style={{ width: "6rem", height: "6rem" }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container px={3}>
            <Grid item xs={12} sm={12} md={12}>
              <ArgonButton variant="gradient" color="success" fullWidth onClick={UpdatePayment}>
                Submit
              </ArgonButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
AddPaymentModal.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
};

export default AddPaymentModal;
