import React, { useEffect, useState } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Button, Card } from "@mui/material";
import axios from "configs/axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import ArgonTypography from "components/ArgonTypography";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

// Import the CSS file
import "../ManualSection/manualStyle.css";
import ArgonButton from "components/ArgonButton";
const WhatsNew = () => {
  const actionDispatcher = useDispatch();
  const [editorData, setEditorData] = useState("");

  // ************************** post data Through Api stored into a state   *******************************

  const getSelectCategory = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.post(`admin/add/whats/new/data`, {
        whatsNew: editorData,
      });
      //   console.log("whatsNew====>", res?.data);
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // *************************************************************************************************

  // ********************Get poicy data  function********************
  const getPolicyData = async () => {
    try {
      const res = await axios.get(`admin/get/whats/new/data`);
      //   console.log("first,res======>", res?.data?.data?.whatsNewData);
      setEditorData(res?.data?.data?.whatsNewData);
    } catch (error) {
      console.log("error", error);
    }
  };
  // ***************************************************************************************

  // ******************************Stored VAlue inside the state**********************************
  const handleEditorChange = (editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  // *********************************************************************************************
  useEffect(() => {
    getPolicyData(editorData);
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card className="container">
          <form onSubmit={getSelectCategory}>
            <Box className="title">
              <ArgonTypography variant={"h5"}>Whats New</ArgonTypography>
            </Box>
            <CKEditor
              editor={Editor}
              // config={editorConfiguration}
              config={{
                ckfinder: {
                  // Upload the images to the server using the CKFinder QuickUpload command
                  // You have to change this address to your server that has the ckfinder php connector
                  uploadUrl: "admin/save/gurash/saving/image",
                },
              }}
              data={editorData ? editorData : ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => handleEditorChange(editor)}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              <ArgonButton variant="gradient" color="success" sx={{ margin: "1rem" }} type="submit">
                Submit
              </ArgonButton>
            </Box>
          </form>

          <Footer />
        </Card>
      </DashboardLayout>
    </>
  );
};

// Typechecking props for the HomeProductSectio

export default WhatsNew;
