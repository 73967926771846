import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import AdvertisementTableData from "./AdvertisementTableData";
import Table from "examples/Tables/Table";
import ArgonButton from "components/ArgonButton";
import AddBudgetModal from "./AddBudgetModal";
import axios from "configs/axios";
import { openSnackbar } from "../../../redux/action/defaultActions";
import { useDispatch, useSelector } from "react-redux";

const AdvertisementHeroTable = () => {
  const actionDispatcher = useDispatch();
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [budgetValue, setBudgetValue] = useState("");
  const [data, setData] = useState(budgetValue);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const search = useSelector((state) => state.search);

  //   // ****************************Advertisement UsER get Budeget Section*********************************
  const getBudget = async () => {
    try {
      const res = await axios.get(`admin/get/advertisement/budget`);
      // console.log("getBudget===>", res?.data);
      setBudgetValue(res?.data?.budget);
      setData(res?.data?.budget);
    } catch (error) {
      console.log("error===>", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };

  // ***************************************************************************************************

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };
  // ----------------------------------------------------------------------------------------------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    AdvertisementTableData(page, rowsPerPage, search.query).then(
      ({ columns, rows, noOfAdvertisements, noOfPages }) => {
        setTableData({ columns, rows, noOfAdvertisements, noOfPages });
      }
    );
    getBudget();
  }, [page, rowsPerPage, search.query]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <ArgonBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Grid container p={3} spacing={2}>
            <Grid item xs={12} sm={9} md={9}>
              <Typography variant="h5">Advertisement </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <ArgonButton
                variant="gradient"
                color="success"
                fullWidth
                onClick={() => {
                  setData(budgetValue);
                  setOpen(true);
                }}
              >
                Set Ads Budget
              </ArgonButton>
            </Grid>
          </Grid>
          <AddBudgetModal
            handleOpen={handleOpen}
            open={open}
            budgetValue={budgetValue}
            data={data}
            setData={setData}
          />
          {tableData ? (
            <>
              <Table
                columns={tableData.columns}
                rows={tableData.rows}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                noOfPages={tableData.noOfPages ? tableData.noOfPages : 0}
                noOfUsers={tableData.noOfAdvertisements ? tableData.noOfAdvertisements : 0}
              />
            </>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Box>
          )}
        </ArgonBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
};

export default AdvertisementHeroTable;
