import { Box, Card, Grid, IconButton, Pagination, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HorizontalScroller from "./Scroller/HorizontalScroller";
import axios from "configs/axios";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import HomeProductSection from "./HomeProductSection";
import imagesNotFound from "../../../assets/images/productNotFound.jpg";
import dayjs from "dayjs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "examples/Footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SellingDeleteModal from "./Single DataDetails/SellingDeleteModal";

const SellingMyItem = () => {
  const navigate = useNavigate();
  const actionDispatcher = useDispatch();
  const search = useSelector((state) => state.search);
  const [data, setData] = useState([]);
  const [pageChange, setPageChange] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [category, setCategory] = useState("all");
  const [selectedValue, setSelectedValue] = useState(false);

  // **************************** navigate Single  Prodcuct Details *********************************

  const handleSingleProduct = (id) => {
    navigate(`/product/singleProduct/${id}`);
  };
  // **************************************************************************************

  // ************************** SideBar Selected Selling Item  data *******************************

  const handleCategory = async (category) => {
    actionDispatcher(isLoading(true));

    try {
      const res = await axios.get(
        `admin/get/sellingItems/subCategory/products?sellingSubCategory=${category}&limitPerPage=50&pageNo=${
          pageChange ? pageChange : 1
        }`
      );
      // console.log("responseee=====>>", res?.data);
      setTotalPage(res?.data?.noOfPages);
      actionDispatcher(isLoading(false));
      setData(res?.data);
      setSelectedValue(true);
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // **************************************************************************************

  // ************************Pagination Selected Value Function****************************
  const handlePageChange = (page) => {
    handleCategory(category, page);
    setPageChange(page);
  };
  // ===============================================================================================

  const pageInitialization = () => {
    setPageChange(1);
  };

  useEffect(() => {
    {
      selectedValue ? "" : handleCategory("all");
    }
  }, [search.query, pageChange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Outlet />
      {/* <HomeProductSection sell={sell} /> */}
      <Grid container>
        <Grid item xs={3}>
          <HorizontalScroller
            handleCategory={handleCategory}
            setPageChange={pageInitialization}
            setCategory={setCategory}
          />
        </Grid>

        <Grid item xs={9} minHeight={"100vh"}>
          {data?.allProducts && data?.allProducts.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 5,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {data?.allProducts?.map((data, index) => {
                return (
                  <Box
                    key={index}
                    xs={12}
                    sm={5.75}
                    md={3.75}
                    lg={2.75}
                    flexDirection={"row"}
                    sx={{
                      position: "relative",
                      "&:hover .MuiBox-root": {
                        transition: "all 300ms ease",
                        opacity: "1",
                        height: "12.5rem",
                      },
                    }}
                  >
                    <Card
                      sx={{
                        minWidth: 200,
                        maxWidth: 200,
                        minHeight: 200,
                        maxHeight: 200,

                        padding: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      {data?.images && data?.images.length > 0 ? (
                        <img
                          src={data?.images[0]?.imageUrl}
                          alt="Image"
                          loading="lazy"
                          style={{
                            minWidth: "11rem",
                            maxWidth: "11rem",
                            minHeight: "5rem",
                            maxHeight: "5rem",
                            borderRadius: "4px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img
                          src={imagesNotFound}
                          alt="Image"
                          loading="lazy"
                          style={{
                            minWidth: "11rem",
                            maxWidth: "11rem",
                            minHeight: "5rem",
                            maxHeight: "5rem",
                            borderRadius: "4px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                      {/* <ArgonButton
                        sx={{ position: "absolute", top: 5, right: 5 }}
                        onClick={() => handleDeleteClick(data?._id)}
                      >
                        {hoveredCardId === data?._id && (
                          <DeleteIcon
                            style={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              zIndex: 1,
                              color: "#5c5b5b",
                            }}
                          />
                        )}
                      </ArgonButton> */}

                      <Box>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                          रू {data?.price}
                        </Typography>

                        <Typography variant="h6">{data?.title}</Typography>

                        <Grid container>
                          <Grid item sm={8} md={8} lg={8}>
                            <Typography sx={{ fontSize: "12px" }} color="text.secondary">
                              {data?.address?.wardName} &nbsp; {data?.address?.wardNumber}
                            </Typography>
                          </Grid>
                          <Grid item sm={4} md={4} lg={4}>
                            <Typography
                              sx={{ fontSize: "12px", textAlign: "end" }}
                              color="text.secondary"
                            >
                              {dayjs(data?.updatedAt).format("DD/MM/YY")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                    <Box
                      sx={{
                        top: 0,
                        opacity: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        width: "100%",
                        height: "87%",
                        borderRadius: "6px",
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        zIndex: "1000",
                      }}
                    >
                      <Stack direction={"row"}>
                        <IconButton
                          sx={{
                            color: "white",
                          }}
                          onClick={() => handleSingleProduct(data?._id)}
                        >
                          <VisibilityIcon color="white" />
                        </IconButton>
                        <SellingDeleteModal id={data?._id} handleCategory={handleCategory} />
                      </Stack>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Card>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={"2rem"}>
                <Typography> No Product Avilable</Typography>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
      <Box width={"96%"} display={"flex"} justifyContent={"end"}>
        {totalPage > 0 ? (
          <Pagination
            count={totalPage}
            page={pageChange}
            onChange={(event, number) => handlePageChange(number)}
            color="primary"
          />
        ) : (
          <></>
        )}
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default SellingMyItem;
