import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "configs/axios";
import ArgonButton from "components/ArgonButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BusinessModal = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const userId = useParams();

  const [data, setData] = useState([]);
  // ****************************Single UsER Details Section*********************************
  const handleBusinessUser = async () => {
    try {
      const res = await axios.get(`admin/get/user/business/profile/${userId?.id}`);
      // console.log("SingleUsers Business===>", res?.data);
      setData(res?.data);
    } catch (error) {
      console.log("error===>", error);
    }
  };

  useEffect(() => {
    handleBusinessUser();
  }, []);

  // ****************************************************************************************

  return (
    <DashboardLayout>
      <DashboardNavbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Card>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          <Typography variant="h5" component="div" color={"#8b8f8c"}>
            Business Profile
          </Typography>
          <ArgonButton variant={"contained"} color={"success"} onClick={() => navigate(-1)}>
            {" "}
            <ArrowBackIcon /> Go Back
          </ArgonButton>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            height: "22rem",
          }}
        >
          <img
            alt="img"
            style={{
              margin: "1rem",
              maxHeight: "100%",
              display: "block",
              overflow: "hidden",
              width: "auto",
              objectFit: "cover",
              borderRadius: "5px",
            }}
            src={data?.businessProfile?.storeImage?.imageUrl}
          />
        </Box>
        <Grid container spacing={1} sx={{ margin: "1rem" }}>
          {data?.businessProfile?.images?.map((item, index) => {
            return (
              <>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={2}
                  sx={{ alignContent: "center", justifyContent: "center", height: "15rem" }}
                >
                  <img
                    key={index}
                    src={item?.imageUrl}
                    alt={`Image${index}`}
                    // style={{ width: "100%", height: "100%", objectFit: "fill" }}
                    style={{
                      maxHeight: "100%",
                      display: "block",
                      overflow: "hidden",
                      width: "auto",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>

        <CardContent>
          <Grid container>
            <Grid item container sm={12} md={6} lg={6} display={"flex"}>
              <Grid item>
                <Typography variant="h6" component="div" color={"#8b8f8c"}>
                  Contact :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  &nbsp;
                  {data?.businessProfile?.countryCode}
                  {data?.businessProfile?.contactNo}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container sm={12} md={6} lg={6} display={"flex"}>
              <Grid item>
                <Typography variant="h6" component="div" color={"#8b8f8c"}>
                  Address :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  &nbsp;
                  {data?.businessProfile?.address?.wardName} ,{" "}
                  {data?.businessProfile?.address?.wardNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container sm={12} md={6} lg={6} display={"flex"}>
              <Grid item>
                <Typography variant="h6" component="div" color={"#8b8f8c"}>
                  Created At :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  &nbsp;
                  {dayjs(data?.createdAt).format("DD/MM/YY")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container sm={12} md={6} lg={6} display={"flex"}>
              <Grid item>
                <Typography variant="h6" component="div" color={"#8b8f8c"}>
                  Business Name :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  &nbsp;
                  {data?.businessProfile?.businessName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container sm={12} md={6} lg={6} display={"flex"}>
              <Grid item>
                <Typography variant="h6" component="div" color={"#8b8f8c"}>
                  Business Introduction :
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  &nbsp;
                  {data?.businessProfile?.businessIntroduction}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} md={6} lg={6} display={"flex"}>
              <Typography variant="h6" component="div" color={"#8b8f8c"}>
                Business Type :
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "500" }}>
                &nbsp;
                {data?.businessProfile?.typeOfBusiness}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6} lg={6} display={"flex"}>
              <Typography variant="h6" component="div" color={"#8b8f8c"}>
                Your Services :
              </Typography>
              &nbsp;
              {data?.businessProfile?.yourServices?.map((item, index) => {
                return (
                  <Typography key={index} variant="h6" sx={{ fontWeight: "500" }}>
                    {item} &nbsp;,&nbsp;
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};
BusinessModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  item: PropTypes.object,
};

export default BusinessModal;
